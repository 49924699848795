import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <p>{`If you haven’t read the syntax highlighting guide it’s recommended start there.`}</p>
    <p><a parentName="p" {...{
        "href": "/guides/syntax-highlighting"
      }}>{`Read syntax highlighting guide`}</a></p>
    <h1 {...{
      "id": "live-code"
    }}>{`Live code`}</h1>
    <p>{`An increasingly common approach for live code editors is to overload the
code block.  This is often done so that the code shows up nicely when rendered
to GitHub and it’s a nice usage of meta strings.`}</p>
    <h2 {...{
      "id": "code-block-meta-string"
    }}>{`Code block meta string`}</h2>
    <p>{`After the language in code fences you can add `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pairs which will
be automatically passed as props to your code block.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`  \`\`\`js live=true
`}</code></pre>
    <h2 {...{
      "id": "component"
    }}>{`Component`}</h2>
    <p>{`If `}<inlineCode parentName="p">{`live`}</inlineCode>{` isn’t passed to the code component you can render syntax highlighting.
If `}<inlineCode parentName="p">{`live`}</inlineCode>{` is present you can return `}<a parentName="p" {...{
        "href": "https://github.com/FormidableLabs/react-live"
      }}>{`react-live`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/components/CodeBlock.js
import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
import {LiveProvider, LiveEditor, LiveError, LivePreview} from 'react-live'

export default ({children, className, live}) => {
  const language = className.replace(/language-/, '')

  if (live) {
    return (
      <div style={{marginTop: '40px'}}>
        <LiveProvider code={children}>
          <LivePreview />
          <LiveEditor />
          <LiveError />
        </LiveProvider>
      </div>
    )
  }

  return (
    <Highlight {...defaultProps} code={children} language={language}>
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={{...style, padding: '20px'}}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({line, key: i})}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({token, key})} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
`}</code></pre>
    <h2 {...{
      "id": "passing-to-the-mdxprovider"
    }}>{`Passing to the MDXProvider`}</h2>
    <p>{`Now that you have a code block component you need to pass it to
MDXProvider in the components object so that it is rendered.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.js
import React from 'react'
import {MDXProvider} from '@mdx-js/tag'

import CodeBlock from './components/CodeBlock'

const components = {
  pre: props => <div {...props} />,
  code: CodeBlock
}
export default props => (
  <MDXProvider components={components}>
    <main {...props}>
    </main>
  </MDXProvider>
)
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      